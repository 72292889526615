<template>
  <div class="create-password-dialog">
    <v-icon
      class="close-icon"
      :size="25"
      color="black"
      @click="$emit('hide')"
    >
      close
    </v-icon>
    <div class="dialog-header">
      <div class="dialog-title">{{ $t('change_pass') }}</div>
    </div>

    <div class="dialog-body">
      <v-form
        v-model="isFormValid"
        class="password-form"
        ref="form"
      >
        <base-input
          class="password-input"
          :label="$t('old_pass')"
          v-model="form.oldPassword.model"
          :append-icon="form.oldPassword.show ? 'mdi-eye' : 'mdi-eye-off'"
          :type="form.oldPassword.show ? 'text' : 'password'"
          :rules="[rules.lenPassword]"
          :error="!!errors.oldPassword"
          :error-messages="errors.oldPassword"
          color="secondary"
          data-testid="old-password"
          tabindex="1"
          @input="onInput"
          @click:append="form.oldPassword.show = !form.oldPassword.show"
        />
        <base-input
          class="password-input"
          :label="$t('new_pass')"
          v-model="form.newPassword.model"
          :append-icon="form.newPassword.show ? 'mdi-eye' : 'mdi-eye-off'"
          :type="form.newPassword.show ? 'text' : 'password'"
          :rules="[rules.lenPassword]"
          :error="!!errors.newPassword"
          :error-messages="errors.newPassword"
          color="secondary"
          data-testid="new-password"
          tabindex="2"
          @click:append="form.newPassword.show = !form.newPassword.show"
        />
        <base-input
          class="password-input"
          :label="$t('confirm_pass')"
          v-model="form.confirmPassword.model"
          :append-icon="form.confirmPassword.show ? 'mdi-eye' : 'mdi-eye-off'"
          :type="form.confirmPassword.show ? 'text' : 'password'"
          :rules="[rules.lenPassword, passwordConfirmationRule]"
          :error="!!errors.confirmPassword"
          :error-messages="errors.confirmPassword"
          color="secondary"
          data-testid="confirm-password"
          tabindex="2"
          @click:append="form.confirmPassword.show = !form.confirmPassword.show"
        />
      </v-form>
    </div>

    <div class="dialog-footer">
      <base-button
        class="action-button"
        btn-type="secondary-light"
        :disabled="!isFormValid || !isFormFilled || !hasNotErrors"
        :loading="loading"
        @click="submit"
      >
        {{ $t("save") }}
      </base-button>
    </div>
  </div>
</template>

<script>
import BaseButton from "@/components/base/BaseButton";
import BaseInput from "@/components/base/BaseInput";
import {isEmpty} from "lodash";
import {mapActions} from "vuex";

export default {
  name: "ChangePasswordDialog",
  components: { BaseButton, BaseInput },
  data() {
    return {
      form: {
        oldPassword: {
          show: false,
          model: "",
        },
        newPassword: {
          show: false,
          model: "",
        },
        confirmPassword: {
          model: "",
          show: false,
        },
      },
      rules: {
        lenPassword: (val) => val.toString().length >= 8 || this.$t("pass_minimum")
      },
      errors: {},
      loading: false,
      isFormValid: false
    }
  },
  computed: {
    passwordConfirmationRule() {
      return () => (this.form.newPassword.model ===  this.form.confirmPassword.model) || this.$t("pass_match")
    },
    isFormFilled() {
      return !!this.form.newPassword.model && !!this.form.confirmPassword.model
    },
    hasNotErrors() {
      return isEmpty(this.errors)
    }
  },
  methods: {
    ...mapActions(['changePassword']),
    async submit() {
      if (this.loading) return;
      this.loading = true;
      this.errors = {};
      const {form} = this.$refs;
      if (form?.validate()) {
        try {
          // TODO: create password endpoint?
          await this.changePassword({
            current_password: this.form.oldPassword.model,
            password: this.form.newPassword.model,
          })
          this.$emit('submit')
        } catch (e) {
          console.log(e)
          if (e.data?.errors) {
            const errors = Object.entries(e.data.errors);
            errors.forEach(([key, value]) => {
              let [msg] = value;
              if (msg === 'is invalid' || msg === 'invalid') {
                msg = this.$t('pass_incorrect')
              }
              this.$set(this.errors, key, msg);
            });
          } else {
            console.error(e);
          }
        }
      }
      this.loading = false
    },
    onInput() {
      this.errors = {}
    }
  }
}
</script>

<style lang="scss" scoped>

.create-password-dialog {
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  justify-self: center;
  position: relative;
  padding: 24px;
  background: linear-gradient(
      0deg,
      rgba(255, 255, 255, 0.1),
      rgba(255, 255, 255, 0.1)
  ),
  #ffffff;
  box-shadow: 0 10px 14px rgba(0, 0, 0, 0.5);
  border-radius: 10px;
  color: #000000;
  min-height: 320px;

  .close-icon {
    position: absolute;
    top: 20px;
    right: 20px;
    cursor: pointer;
    z-index: 99999;
    &:hover,
    &:focus {
      &:after {
        opacity: 0;
      }
    }
  }

  .dialog-header {
    position: relative;
    display: flex;
    justify-content: space-between;
    align-items: center;
    flex-direction: column;
    width: 100%;
    flex: 0 0;

    .dialog-title {
      width: 100%;
      padding: 0;
      font-style: normal;
      font-weight: bold;
      font-size: 24px;
      line-height: 150%;
      text-align: left;
      color: #000000;
      margin-top: 8px;
    }

    .dialog-description {
      width: 100%;
      padding: 0;
      font-style: normal;
      font-weight: 500;
      font-size: 14px;
      line-height: 150%;
      text-align: left;
      color: #000000;
      margin-top: 32px;
    }
  }

  .dialog-body {
    display: flex;
    flex-direction: column;
    justify-content: center;
    width: 100%;
    flex: 1 0;
    font-style: normal;
    font-weight: 500;
    font-size: 14px;
    line-height: 18px;
    padding: 0;
    text-align: left;
    color: black;

    .password-form {
      margin-top: 22px;

      .password-input {
        ::v-deep .v-input__slot {
          // min-height: 48px;
          .v-label {
            text-transform: uppercase;
            font-size: 12px;
          }
        }
      }
    }
  }
  .dialog-footer {
    display: flex;
    width: 100%;
    margin-top: 18px;
    margin-bottom: 5px;
    padding-top: 0;
    padding-bottom: 0;
    justify-content: center;

    .action-button {
      width: 328px;
      height: 44px;
      font-size: 14px;
      padding: 0 15px;
    }
  }

  @media screen and (max-width: 499px) {
    position: fixed;
    bottom: 0;
    right: 0;
    border-bottom-left-radius: 0;
    border-bottom-right-radius: 0;
  }
}
</style>
