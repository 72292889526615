<template>
  <div class="subscriptions-list">
    <!-- stripe subscription -->

    <div class="subscription-item">
      <div class="sub-title">{{ subscription.title }}</div>
      <div class="sub-plan">
        <span>{{ subscriptionPlan }}</span>
      </div>
      <!-- active -->
      <div class="sub-duration" v-if="subscription.status === 'active'">
        <i class="material-icons-outlined">calendar_today</i>
        <div v-if="!subscription.cancel_at">
          <span>{{ $t("renews_on") }} </span>
          <span>{{ transformDate(subscription.current_period_end) }}</span>
        </div>
        <div v-if="subscription.cancel_at">
          <span>{{ $t("expires_on") }} </span>
          <span>{{ transformDate(subscription.cancel_at) }}</span>
        </div>
      </div>
      <!-- unpaid || past_due -->
      <div
        class="sub-error"
        v-if="
          subscription.status === 'past_due' || subscription.status === 'unpaid'
        "
      >
        <div class="error-text" v-if="subscription.status === 'past_due'">
          <span>{{ $t("sub_status_payment_failed_line_1") }} </span>
          <span>{{ $t("sub_status_payment_failed_line_2") }}</span>
        </div>

        <div class="error-text" v-if="subscription.status === 'unpaid'">
          <span>{{ $t("sub_status_update_payment_line_1") }}</span>
          <span>{{ $t("sub_status_update_payment_line_2") }}</span>
        </div>
      </div>
      <div class="manage-button cursor-pointer" @click="openCustomerPortal">
        <span>{{ $t("manage_subscription") }}</span>
      </div>
    </div>
  </div>
</template>
<script>
import moment from "moment";
import { toNumber } from "lodash";
import { mapActions } from "vuex";
export default {
  data: () => ({
    btnDisabled: false,
  }),
  props: ["subscription"],
  computed: {
    subscriptionPrice() {
      if (!this.subscription?.unit_amount) return;
      let dollars = toNumber(this.subscription.unit_amount) / 100;

      return dollars.toLocaleString("en-US", {
        style: "currency",
        currency: "USD",
      });
    },
    subscriptionPlan() {
      if (!this.subscription) return;
      if (this.subscription.unit_amount === "0") return this.$t("free");

      return `${this.subscriptionPrice} ${
        this.subscription.price_interval === "year"
          ? this.$t("per_year")
          : this.$t("per_month")
      }`;
    },
  },
  methods: {
    ...mapActions(["getCustomerPortalLink"]),
    transformDate(date) {
      if (date) return moment(date).format("MMMM DD, YYYY");
    },
    async openCustomerPortal() {
      if (this.btnDisabled) return;

      this.btnDisabled = true;
      let link = await this.getCustomerPortalLink();
      if (link.url) window.location = link.url;
      this.btnDisabled = false;
    },
  },
  mounted() {},
};
</script>
<style lang="scss" scoped>
.subscriptions-list {
  font-size: 16px;
  line-height: 21px;
  .subscription-item {
    .sub-title {
      font-weight: bold;
    }
    .sub-plan {
      margin: 8px 0;
    }
    .sub-duration {
      display: flex;
      flex-direction: row;
      align-items: center;
      color: #a9a9a9;
      i {
        font-size: 18px;
        margin-right: 8px;
      }
    }
    .sub-error {
      color: #d1595f;
      margin-top: 8px;

      .error-text {
        display: flex;
        flex-direction: column;
        @media screen and (max-width: 499px) {
          display: inline;
        }
      }
    }
    .sub-notifications {
      margin-top: 8px;
      color: #539e47;

      display: flex;
      flex-direction: column;
    }
    .manage-button {
      margin-top: 36px;
      font-size: 14px;
      font-weight: bold;
      line-height: 14px;
      text-transform: uppercase;

      a {
        text-decoration: none;
      }
    }
    .sub-divider {
      background: rgba(255, 255, 255, 0.4);
      height: 1px;
      width: 100%;
      margin: 36px 0;
    }
  }
}
</style>